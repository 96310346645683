<template>
  <div class="row contrail-index">
    <div class="row-left" :style="{ width: showCond ? 'calc(100% - 300px)' : '100%'}">
      <div class="row" :style="{ height: showBottom ? 'calc(100% - 250px)' : '100%' }">
        <contrail-map v-bind="$attrs" class="contrail-map" ref="cMap" :speed="speed" @changePercent="changePercent" :deviceSn="deviceSn" :condition="condition" @changeDis="totalDis = $event"></contrail-map>
      </div>
      <div class="record">
        <div class="row record-bottom align-center justify-between" style="padding: 0 20px;">
          <div class="row">
            <span>轨迹数据</span>
          </div>
          <div @click="showBottom = !showBottom">
            <i :class=" showBottom ? 'el-icon-download' : 'el-icon-upload2' "></i>
          </div>
        </div>
        <record v-if="showBottom"></record>
      </div>
    </div>
    <div class="row-right" v-show="showCond">
      <contrail-search :startTimeInfo="startTime" :endTimeInfo="endTime" @changeStatus="handleChangeSatus" :selectType="0" @changeSpeed="handleChangeSpeed" :percent="percent" :obj="obj" @change="condition = $event" :totalDis="totalDis"></contrail-search>
    </div>
  </div>
</template>
<script>
  import ContrailMap from './components/contrailMap.vue'
  import Record from './components/record.vue'
  import ContrailSearch from './components/contrailSearch.vue'

  export default {
    name: 'ContrailIndex',
    components: { ContrailMap, Record, ContrailSearch },
    data() {
      return {
        showCond: true,
        showBottom: true,
        speed: 10,
        percent: 0,
        deviceSn: '',
        condition: {},
        obj: {
          carVin: '',
          sn: '',
          wifi: false,
          deviceSn: ''
        },
        totalDis: 0,
        item: {}
      }
    },
    props: {
      startTime: {
        type: String,
        default: ''
      },
      endTime: {
        type: String,
        default: ''
      }
    },
    watch: {
      item: {
        // 每次切换设备进入， 初始化界面状态
        handler() {
          this.obj = {
            carVin: this.item.carVin,
            sn: this.item.sn,
            wifi: this.item.wifi,
            deviceSn: this.item.deviceSn
          }
          this.deviceSn = this.item.deviceSn
          this.startTime = ''
          this.endTime = ''
          this.percent = 0
        },
        immediate: true,
        deep: true
      }
    },
    mounted() {
      // 重新进入，清空轨迹列表数据
      this.$store.commit('map/setTracks', [])
    },
    computed: {
    },
    created() {
      const query = this.$route.query
      this.deviceSn = query.imei
      this.carVin = query.carVIn
      this.obj = query
    },
    methods: {
      handleChangeSatus(status) {
        if (!status) return
        switch (status) {
          case 1: // 启动
            this.totalDis = 0
            this.$refs.cMap.reset()
            this.$refs.cMap.start()
            break
        }
      },
      handleChangeSpeed(speed) {
        this.speed = speed
      },
      changePercent(percent) {
        this.percent = percent
      }
    }
  }
</script>
<style lang="less" scoped>
  .cursor {
    cursor: pointer;
  }

  .contrail-index {
    height: 100vh;
  }

  .row-left {
    text-align: center;
    display: flex;
    flex-direction: column;
    color: #fff;

    .title {
      background-color: #8cc8ff;
      height: 43px;
      display: flex;
      justify-content: space-between;
      width: 100%;
      padding: 0 10px;
    }

    .contrail-map {
      height: 100%;
      width: 100%;
    }

    .con-btn {
      border: 1px solid #fcfcfc;
      padding: 2px 10px;
      border-radius: 4px;
      margin-left: 10px;

      span {
        margin-left: 2px;
      }
    }

    .record-bottom {
      height: 40px;
      background-color: #8cc8ff;
    }
  }

  .row-right {
    width: 300px;
  }
</style>

<template>
  <div tip="Loading..." :spinning="spinning">
    <div class="contrail-search">
      <div class="title">
        <div class="devider"></div>
        <span>播放选项</span>
      </div>
      <div class="row align-center">
        <div class="car-info">
          <img src="@/assets/car/online.png" alt="light" class="car" />
        </div>
        <div class="col">
          <span>{{ obj.carNum || obj.carVin || '未装车' }}</span>
          <span>{{ obj.imei }}</span>
        </div>
      </div>
      <div class="condition-item" v-if="startTimeInfo === ''">
        <span class="field">选择时间</span>
        <ul>
          <li :class="selectType === item.value ? 'select-time' : ''" v-for="item in timeType" :key="item.value" @click="handleTypeChange(item.value)">
            {{ item.name }}
          </li>
        </ul>
      </div>
      <div class="condition-item">
        <span class="field">开始时间</span>
        <!-- <v-date-picker show-time placeholder="请选择开始时间" v-model="startTime" :disabled="startTimeInfo !== '' ? true : false" /> -->
        <el-date-picker
          size="small"
          v-model="startTime"
          type="datetime"
          placeholder="选择日期时间"
          default-time="12:00:00">
        </el-date-picker>
      </div>
      <div class="condition-item">
        <span class="field">结束时间</span>
        <!-- <v-date-picker show-time placeholder="请选择结束时间" v-model="endTime" :disabled="startTimeInfo !== '' ? true : false" /> -->
        <el-date-picker
          v-model="endTime"
          size="small"
          type="datetime"
          placeholder="选择日期时间"
          default-time="12:00:00">
        </el-date-picker>
      </div>
      <div class="condition-item">
        <span class="field">筛选条件</span>
        <!-- <v-checkbox-group v-model="checkedList" @change="onChange" class="checkbox-groups">
          <v-checkbox :value="item.value" v-for="item in types" :key="item.value">
            {{ item.name }}
          </v-checkbox>
        </v-checkbox-group> -->
        <el-checkbox-group v-model="checkedList" @change="onChange" size="small">
          <el-checkbox :label="item.name" v-for="item in types" :key="item.value"></el-checkbox>
        </el-checkbox-group>
      </div>
      <div class="condition-item">
        <span class="field">播放速度</span>
        <!-- <v-input-number autocomplete="off" v-model="times" :step="50" :min="200" :max="1000" step-strictly @change="handleSpeedChange" /> -->
        <el-input-number v-model="times" @change="handleSpeedChange" size="small" :min="200" :max="1000" label="描述文字"></el-input-number>
      </div>
      <div class="condition-item">
        <span class="field">GPS定位</span>
        <!-- <el-radio name="radioGroup" :default-value="1" v-model="gps"> -->
          <el-radio :label="1" v-model="gps" size="small"> 是 </el-radio>
          <el-radio :label="0" v-model="gps" size="small"> 否 </el-radio>
        <!-- </el-radio> -->
      </div>
      <div class="condition-item">
        <span class="field">是否纠偏</span>
        <!-- <v-radio-group name="radioGroup" :default-value="0" v-model="correct">
          <v-radio :value="1"> 是 </v-radio>
          <v-radio :value="0"> 否 </v-radio>
        </v-radio-group> -->
        <el-radio :label="1" v-model="correct" size="small"> 是 </el-radio>
        <el-radio :label="0" v-model="correct" size="small"> 否 </el-radio>
      </div> 
      <div class="condition-item">
        <span class="field">显示地址</span>
        <!-- <v-radio-group name="radioGroup" :default-value="0" v-model="address">
          <v-radio :value="true"> 是 </v-radio>
          <v-radio :value="false"> 否 </v-radio>
        </v-radio-group> -->
        <el-radio :label="true" v-model="address" size="small"> 是 </el-radio>
        <el-radio :label="false" v-model="address" size="small"> 否 </el-radio>
      </div>
      <div class="condition-item">
        <el-button type="primary" @click="$emit('changeStatus', 1)" size="small">查询</el-button>
        <el-button type="default" @click="reset()" size="small">重置</el-button>
      </div>
    </div>
    <div class="lc">
      <el-row class="row align-center">
        <el-col class="col align-center" :span="8">
          <img src="@/assets/device/yb.png" class="icon" />
          <span class="ctips">行驶里程</span>
        </el-col>
        <el-col :span="16" class="row align-center justify-center" style="color: #ff3a61">
          <span class="gl">{{ totalDis.toFixed(2) }}</span><span>公里</span>
        </el-col>
      </el-row>
      <el-row class="margin-top-10">
        <el-col>
          <p style="color: #2497db">备注：</p>
          <p style="color: #333" class="remark"> 
            为了轨迹的显示更直观，通过智能算法，减少非必要的轨迹统计，导致里程和其他模块有一定的差异。
          </p>
        </el-col>
      </el-row>
    </div>
    <div class="stop-event" style="margin: 10px">
      <!-- <div class="col" style="height: 100%"> -->
        <div class="clearfix">
          <span>停车事件列表</span>
        </div>
        <div v-for="item in items" :key="item.endTime" class="event-item">
          <p>
            <span class="stop-field">开始时间</span><span>{{ item.beginTime }}</span>
          </p>
          <p>
            <span class="stop-field">结束时间</span><span>{{ item.endTime }}</span>
          </p>
          <p>
            <span class="stop-field">停车时长</span><span>{{ item.parkingTime }}</span>
          </p>
          <p>
            <span class="stop-field">停车位置</span><span>{{ item.address }}</span>
          </p>
        </div>
      <!-- </div> -->
    </div>
  </div>
</template>
<script>
  import { addDate } from '@/utils/util'
  import moment from 'moment'
  import bus from '@/utils/bus.js'
import { mapState } from 'vuex'
  const plainOptions = ['Apple', 'Pear', 'Orange']
  const timeType = [
    { name: '今天', value: 0 },
    { name: '昨天', value: 1 },
    { name: '近7天', value: 7 },
    { name: '近30天', value: 30 },
  ]
  const types = [{ name: '速度大于5km', value: 1 }]
  export default {
    name: 'ContrailSearch',
    data() {
      return {
        spinning: false,
        plainOptions: plainOptions,
        checkedList: [],
        types,
        timeType,
        selectType: 0,
        times: '500',
        startTime: new Date(),
        endTime: new Date(),
        gps: 1,
        correct: 0,
        changeStatus: 0,
        address: false,
        // items: []
      }
    },
    props: {
      percent: {
        type: Number,
        default: 0,
      },
      obj: {
        type: Object,
        default: () => {},
      },
      totalDis: {
        type: Number,
        default: 0,
      },
      startTimeInfo: {
        type: String,
        default: '',
      },
      endTimeInfo: {
        type: String,
        default: '',
      },
    },
    computed: {
      ...mapState({
        items: (state) => state.map.tracks ? state.map.tracks.stayPoint : [],
      }),
    },
    watch: {
      selectType: {
        handler(val) {
          const start = addDate(1, -val)
          this.startTime = moment(start.setHours(0, 0, 0, 0))
          if (val === 1) {
            this.endTime = moment(addDate(1, -val).setHours(23, 59, 59))
          } else {
            this.endTime = moment(new Date().setHours(23, 59, 59))
          }
        },
        immediate: true,
      },
      checkedList: {
        handler() {
          this.$emit('change', this.getReturnObj())
        },
        immediate: true,
      },
      startTime: {
        handler() {
          this.$emit('change', this.getReturnObj())
        },
        immediate: true,
      },
      endTime: {
        handler() {
          this.$emit('change', this.getReturnObj())
        },
        immediate: true,
      },
      startTimeInfo: {
        handler() {
          this.startTime = this.startTimeInfo !== '' ? moment(this.startTimeInfo) : this.startTime
          this.endTime = this.startTimeInfo !== '' ? moment(this.endTimeInfo !== '' ? this.endTimeInfo : new Date()) : this.endTime
          if (!this.startTimeInfo) {
            this.selectType = 0
          }
        },
        immediate: true
      },
      endTimeInfo: {
        handler() {
          this.startTime = this.startTimeInfo !== '' ? moment(this.startTimeInfo) : this.startTime
          this.endTime =
            this.startTimeInfo !== '' ? moment(this.endTimeInfo !== '' ? this.endTimeInfo : new Date()) : this.endTime
        },
        immediate: true,
      },
      gps: {
        handler() {
          this.$emit('change', this.getReturnObj())
        },
        immediate: true,
      },
      correct: {
        handler() {
          this.$emit('change', this.getReturnObj())
        },
        immediate: true,
      },
      address: {
        handler() {
          this.$emit('change', this.getReturnObj())
        },
        immediate: true,
      },
    },
    methods: {
      reset() {
        this.selectType = 0
      },
      getReturnObj() {
        return {
          startTime: this.startTime.format('YYYY-MM-DD HH:mm:ss'),
          endTime: this.endTime.format('YYYY-MM-DD HH:mm:ss'),
          checkedList: this.checkedList,
          gps: this.gps,
          correct: this.correct,
          address: this.address
        }
      },
      timeSet(value) {
        var date = new Date(value)
        var timeDate
        const Y = date.getFullYear() + '-'
        const M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-'
        const D = (date.getDate() < 10 ? '0' + date.getDate() : date.getDate()) + ' '
        const h = 23 + ':'
        const m = 59 + ':'
        const s = 59
        timeDate = Y + M + D + h + m + s
        return new Date(timeDate).getTime()
      },
      handleTypeChange(val) {
        this.selectType = val
      },
      onChangeTime() {
        console.log('onChangeTime')
      },
      onOk() {
        console.log('onOk')
      },
      onChange() {
        console.log('onChange')
      },
      handleSpeedChange(e) {
        this.$message({ messge: '设置播放速度后，需要重新查询才生效', type: 'warning' })
        this.$emit('changeSpeed', e)
      },
    },
    created() {
      bus.$on('getloadding', target => {
        this.spinning = target
      })
    }
  }
</script>
<style lang="less" scoped>
  .contrail-search {
    .title {
      height: 40px;
      display: flex;
      align-items: center;
      background-color: #8cc8ff;
      box-sizing: border-box;
      color: #fff;

      .devider {
        height: 16px;
        width: 1px;
        background-color: #fff;
        margin-right: 10px;
      }
    }

    .car-info {
      .car {
        margin: 10px;
      }
    }

    .condition-item {
      display: flex;
      padding: 10px 10px 0;

      button {
        width: calc((100% - 40px) / 4);
      }

      button:not(:last-child) {
        margin-right: 10px;
      }

      .field {
        width: 80px;
        color: #666;
      }

      ul {
        display: flex;
        width: 100%;
        margin: 0;
        padding: 0;
        margin-left: 20px;

        li {
          width: calc(100% / 4);
        }

        li:hover {
          color: #1890ff;
        }
      }

      .checkbox-groups {
        display: flex;
        flex-direction: column;

        label {
          margin: 0 !important;
        }
      }
    }
  }

  .select-time {
    color: #1890ff;
  }

  .stop-field {
    color: #333;
    margin-right: 10px;
    padding: 10px 0px;
  }

  .lc {
    padding: 10px;
    background-color: #e7f8ff;
    border: 1px solid #2497db;
    margin: 10px;
    font-size: 0.08rem;
  }

  .icon {
    width: 50px;
  }

  .ctips {
    font-size: 0.08rem;
    padding-top: 0.08rem;
    color: #2497db;
  }

  .gl {
    font-size: 0.2rem;
    margin-right: 5px;
  }

  .stop-event {
    height: 250px;
    font-size: 0.02rem;
    color: #666;
    overflow: auto;
    border: 1px solid #e8e8e8;
    .clearfix {
      border-bottom: 1px solid #e8e8e8;
      padding: 10px;
    }
  }

  .remark {
    padding-top: 6px;
    letter-spacing: 1px;
  }
  .event-item {
    padding: 10px;
  }
  .event-item:not(:first-child) {
    // margin-top: 10px;
    p {
      margin-top: 0.02rem;
    }
  }
</style>

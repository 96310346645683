<template>
  <div>
    <template slot="footer">
      <van-button v-show="false" @click="cancel">关闭</van-button>
    </template>
    <div style="height:100vh; width: 100%">
      <baidu-map style="height:100%; width: 100%" :center="currentPoint" :zoom="zoom" :scroll-wheel-zoom="true"
        @ready="initMap">
        <bm-marker :position="currentPoint"
          :icon="{ url: require(`@/assets/car/online.png`), size: { width: 36, height: 36 } }" @click="openInfo">
        </bm-marker>
        <bm-info-window :position="currentPoint" title="设备详情" :show="shows" class="info"
          style="width:500px;min-height:350px;" @close="colseInfo">
          <van-row class="margin-top-40">
            <van-col :span="24">
              <span class="title">车辆</span>
              <div>【{{ devicedata.brand }}】</div>
              <div><span class="fontone">车架号:</span> {{ devicedata.carvin }}</div>
              <div><span class="fontone">设备号:</span> {{ devicedata.sn }}</div>
            </van-col>
            <van-col :span="24" v-if="devicedata.reportGps === 1 && devicedata.gpsTime">
              <span class="title">GPS</span>
              <div><span class="fontone">最后定位时间:</span> {{ devicedata.gpsTime }}</div>
              <div><span class="fontone">最后定位位置:</span> {{ devicedata.gpsAddress }}</div>
              <div class="fontred">GPS定位精确度约为10米以内</div>
            </van-col>
            <van-col :span="24" v-if="devicedata.reportGms === 1 && devicedata.gsmTime">
              <span class="title">基站</span>
              <div><span class="fontone">最后定位时间:</span> {{ devicedata.gsmTime }}</div>
              <div><span class="fontone">最后定位位置:</span> {{ devicedata.gsmAddress }}</div>
              <div class="fontred">基站定位精确度约为50-1000米</div>
            </van-col>
            <van-col :span="24" v-if="devicedata.reportWifi === 1 && devicedata.wifiTime">
              <span class="title">WIFI</span>
              <div><span class="fontone">最后定位时间:</span> {{ devicedata.wifiTime }}</div>
              <div><span class="fontone">最后定位位置:</span> {{ devicedata.wifiAddress }}</div>
              <div class="fontred">基站定位精确度约为50-1000米</div>
            </van-col>
            <van-col :span="24" v-if="devicedata.updatetime">
              <span class="title">设备</span>
              <div>【{{ devicedata.alias }}{{ devicedata.wifi === 1 ? '无线' : '有线' }}】</div>
              <div><span class="fontone">设备状态:</span> {{ devicedata.statusDevice }}</div>
              <div><span class="fontone">最后上传时间:</span> {{ devicedata.updatetime }}</div>
            </van-col>
          </van-row>
        </bm-info-window>
      </baidu-map>
    </div>
  </div>
</template>
<script>
import { getDeviceInfo } from '@/api/device'
export default {
  props: {},
  data() {
    return {
      zoom: 18,
      visible: false,
      shows: true,
      currentPoint: {
        lng: 120.320032,
        lat: 31.589666
      },
      devicedata: {
        // carvin: '粤B46500',
        // sn: '123123232',
        // reportGps: 1,
        // gpstime: '2023-02-24 14:35:00',
        // gpsAddress: '深圳市南山区高新园区北科大厦415',
        // updatetime: '2023-02-24 14:35:00',
        // alias: 'JR-02',
        // wifi: 1,
        // statusDevice: '油电通，设备在线',
        // brand: '马萨拉蒂'
      },
      BMap: null,
      map: null,
      key: '', // 外部参数 登录 Token
      fun: '', // 外部参数 功能
      imei: '', // 外部参数 设备号
      carVin: '' // 外部参数 车牌
    }
  },
  watch: {
    $route: {
      handler(to) {
        const type = to.query.type
        // 外部调用 type 为1， 调用定位查询页面， 值为2 时调用轨迹查询页面
        if (type == 2) {
          this.$router.push({ path: '/connect/contrail', query: { ...to.query } })
        }
      },
      immediate: true
    }
  },
  async created() {
    const { key, fun, imei, carVin } = this.$route.query
    this.key = key
    this.fun = fun
    this.imei = imei
    this.carVin = carVin
    if (this.imei) {
      this.intshow()
    } else {
      this.$dialog.alert({
        title: '提示',
        message: '请传入需要定位的设备号',
      }).then(() => { })
    }
  },
  methods: {
    // 重绘地图定位
    initMap({ BMap, map }) {
      this.BMap = BMap
      this.map = map
    },
    cancel() {
      this.visible = false
    },
    colseInfo() {
      this.shows = false
    },
    openInfo() {
      this.shows = true
    },
    async intshow() {
      if (!this.imei) {
        return
      }
      const param = {
        key: this.key,
        fun: this.fun,
        imei: this.imei,
        carVin: this.carVin
      }
      const res = await getDeviceInfo(param)
      if (res && res.success) {
        this.devicedata = res.content[0]
        console.log(this.devicedata, 'deviceData ====')
        this.currentPoint.lng = this.devicedata.lon ? this.devicedata.lon : this.devicedata.wifiLon ? this.devicedata.wifiLon : this.devicedata.gsmLon
        this.currentPoint.lat = this.devicedata.lat ? this.devicedata.lat : this.devicedata.wifiLat ? this.devicedata.wifiLat : this.devicedata.gsmLat
        this.zoom = 18
        this.shows = true
        this.visible = true
      } else {
        this.$dialog.alert({
          title: '提示',
          message: '设备信息查询失败',
        }).then(() => { })
      }
    }
  }
}
</script>
<style lang="less" scoped>
.margin-top-40 {
  margin-top: 0.1rem;
}

.fontred {
  color: red;
}

.title {
  font-size: 0.08rem;
  color: #000;
  font-weight: 600;
}

.van-col {
  div {
    margin-top: 0.04rem;
    margin-left: 0.4rem;
  }
}

.fontone {
  font-size: 0.06rem;
  font-weight: 700;
  line-height: 0.1rem;
}

.col {
  display: flex;
  align-items: center;
}
</style>

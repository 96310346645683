import request from '@/utils/request'

const payapi = {
  pay: '/payment/inside/activePay',
  productList: '/insurance/product/getUserProduct'
}

/**
 * 提交支付
 * @param {*} parameter
 */
export function paysubmit (parameter) {
  return request.get(payapi.pay,parameter)
}
// /**
//  * 获取保单
//  * @param {*} parameter
//  */
//  export function getProductList (parameter) {
//   return request.get(payapi.productList,parameter)
// }
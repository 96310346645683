// import BmlLushu from 'bmaplib.lushu'
const mapUtil = {
  center: { lng: 120.320032, lat: 31.589666 },
  zoom: 3,
  // 初始化地图
  init(Bmap, map) {},
  setCenter({ lat, lng }) {
    return {
      lng: lng,
      lat: lat
    }
  },
  // 地图打点
  addMarker(map, point) {
    const { icon, lng, lat } = point
    const p = new BMap.Point(lng, lat)
    var marker = new BMap.Marker(p)
    marker.setIcon(new BMap.Icon(require(`@/assets/${icon}`), new BMap.Size(30, 30), { anchor: new BMap.Size(27, 13) }))
    marker.setPosition(p)
    map.addOverlay(marker)
  },
  // 地图添加折线
  addPolyline(map, pointsData) {
    if (pointsData != null && pointsData.length > 0) {
      const points = []
      pointsData.forEach(item => {
        points.push(new BMap.Point(item.lng, item.lat))
      })

      // 创建折线
      var polyline = new BMap.Polyline(points)
      polyline.setStrokeOpacity(1)
      polyline.setStrokeWeight(8)
      polyline.setStrokeColor('#087ec4')
      map.addOverlay(polyline)
      return polyline
    }
    return null
  },
  initMoveCar(map, { lat, lng }) {
    var icon = new BMap.Icon(require('@/assets/car/online.png'), new BMap.Size(52, 26), { anchor: new BMap.Size(27, 13) })
    var mkrBus = new BMap.Marker({ lat: lat, lng: lng }, { icon: icon }) // 声明每个点的标注
    map.addOverlay(mkrBus, { autoPan: true })
    return mkrBus
  },
  moveCar(mkrBus, { speed, direction, lat, lng }) {
    const label = new BMap.Label(speed + ' Km/h', { offset: new BMap.Size(0, -20) })
    label.setStyle({ border: '1px solid #e0dcd5', borderRadius: '5px' })
    mkrBus.setLabel(label)
    mkrBus.name = 'mkrBus'
    mkrBus.setRotation(direction - 90)
    mkrBus.setPosition(new BMap.Point(lng, lat))
  },
  // 清空地图
  clearMoveCar(map) {
    var allOverlay = map.getOverlays()
    allOverlay.map(item => {
      map.removeOverlay(item)
    })
  },
  start() {},
  stop() {},
  pause() {},
  forward() {},
  back() {},
  pointToPixel(map, point) {
    const pixel = map.pointToPixel(point)
    return pixel
  }
}

export default mapUtil

<template>
  <div>
    <el-table
      :data="currentPageData"
      style="width: 100%"
      height="250"
      size="small"
      align="center"
      >
      <el-table-column
        label="序号"
        type="index"
        :index="indexMethod"
        align="center"
        width="50">
      </el-table-column>
      <el-table-column
        prop="statusStr"
        label="车辆状态"
        width="300">
      </el-table-column>
      <el-table-column
        prop="speed"
        label="速度(km/h)"
        width="150">
      </el-table-column>
      <el-table-column
        prop="directionStr"
        label="方向">
      </el-table-column>
      <el-table-column
        prop="alarm"
        label="报警">
      </el-table-column>
      <el-table-column
        prop="gpsTime"
        label="定位时间">
      </el-table-column>
      <el-table-column
        prop="lat"
        label="经纬度">
      </el-table-column>
      <el-table-column
        prop="updateTime"
        label="设备时间">
      </el-table-column>
    </el-table>
    <el-pagination
      class="paginat"
      :pageSize="pageSize"
      :currentPage="currentPage"
      background
      v-if="sourceData && sourceData.length > 0"
      layout="prev, pager, next"
      :total="sourceData.length"
      @current-change="currentChange"
      >
    </el-pagination>
  </div>
</template>
<script>
  import { getLocalTime } from '@/utils/util'
import { mapGetters } from 'vuex'
  export default {
    name: 'Record',
    components: { },
    computed: {
      ...mapGetters({
        tracks: 'map/tracks'
      }),
      sourceData() {
        return this.tracks?.listCheck ?? []
      },
      currentPageData() {
        const { currentPage, pageSize, tracks } = this
        const startIndex = (currentPage - 1) * pageSize
        const endIndex = startIndex + pageSize
        console.log(currentPage, startIndex, endIndex)
        let datas = tracks?.listCheck ?? []
        datas = datas.slice(startIndex, endIndex)
        return datas
      }
    },
    data() {
      return {
        currentPage: 1,
        pageSize: 10,
      }
    },
    created() {
    },
    methods: {
      currentChange(pagination) {
        this.currentPage = pagination
      },
      formatDT(sn) {
        return getLocalTime(sn)
      },
      indexMethod(index) {
        return (this.currentPage - 1) * this.pageSize + index + 1;
      }
    }
  }
</script>
<style lang="less" scoped>
  .paginat {
    text-align: right;
  }
</style>

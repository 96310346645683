<template>
  <div class="content">
    <van-search
      v-model="keyword"
      :show-action="showAction"
      placeholder="根据课程名称搜索"
      @search="onSearch"
      @cancel="onCancel"
      @focus="onFocus"
    />
    <div class="courseList" id="courseList">
      <van-pull-refresh v-model="isLoading" @refresh="onRefresh">
        <div
          class="courseItem"
          v-for="(item, index) in courseList"
          :key="index"
        >
          <div class="row justify-between">
            <div>
              <p class="courseTitle">{{ item.courseName }}</p>
              <p class="courseTime">{{ item.durationInfo }}</p>
            </div>
            <div>
              <van-tag
                type="success"
                round
                :plain="false"
                :color="statusOption[item.status].color"
                >{{ statusOption[item.status].name }}</van-tag
              >
            </div>
          </div>
          <div class="row justify-between">
            <van-button
              style="width: 50px"
              plain
              type="primary"
              size="mini"
              round
              :disabled="!item.examinationId"
              @click="
                goto(
                  `/course/examination?examinationId=${item.examinationId}&courseId=${item.id}`
                )
              "
              >考试</van-button
            >
            <van-button
              plain
              type="primary"
              size="mini"
              round
              @click="showVideo(item)"
              ><van-icon name="play" />观看课程</van-button
            >
          </div>
        </div>
      </van-pull-refresh>
    </div>
    <MyVideo ref="myVideo"></MyVideo>
  </div>
</template>

<script>
import MyVideo from "./video.vue";
import { courseList, courseStart } from "@/api/oa.js";
export default {
  components: {
    MyVideo,
  },
  data() {
    return {
      keyword: "",
      showAction: false,
      statusColor: "red",
      lastPage: false,
      page: 1,
      courseList: [],
      isLoading: false,
      statusOption: {
        0: {
          color: "grey",
          name: "待学习",
        },
        1: {
          color: "red",
          name: "学习中",
        },
        2: {
          color: "#1777ff",
          name: "已学习",
        },
      },
    };
  },
  mounted() {
    let that = this;
    document
      .getElementById("courseList")
      .addEventListener("scroll", that.handleScroll, true);
    this.loadData();
  },
  methods: {
    handleScroll(e) {
      let scrollTop = e.target.scrollTop; //滑入屏幕滚动条滚动时，距离顶部的距离
      let windowHeitht = e.target.clientHeight; //能看到的页面的高度
      let scrollHeight = e.target.scrollHeight; //监控的整个div的高度（包括现在看到的和上下隐藏起来看不到的）
      let total = scrollTop + windowHeitht;
      this.loddingVisible = true;
      if (total == scrollHeight) {
        // console.log("到达底部了");
        if (!this.lastPage) {
          this.loadData();
        }
      }
    },
    async onRefresh() {
      this.page = 1;
      await this.loadData();
      this.isLoading = false;
    },
    async loadData() {
      const params = {
        page: this.page,
        pageSize: 10,
        pageType: 1,
        search: this.keyword,
      };
      const res = await courseList(params);
      this.lastPage = res.data.lastPage;
      if (this.page === 1) {
        this.courseList = res.data.list;
      } else {
        this.courseList = [...this.courseList, ...res.data.list];
      }
      if (!this.lastPage) {
        this.page = res.data.pageNumber + 1;
      }
    },
    courseStartFun(courseId) {
      courseStart({ courseId });
    },
    showVideo(item) {
      this.$refs.myVideo.onShow(item);
      this.courseStartFun(item.id);
    },
    onSearch() {
      // this.$toast(this.keyword);
      this.loadData()
    },
    onFocus() {
      this.showAction = true;
    },
    onCancel() {
      this.showAction = false;
    },
    goto(url) {
      url && this.$router.push({ path: url });
    },
  },
};
</script>

<style lang="less" scoped>
.content {
  background-color: #f8f8f8;
  height: 100vh;
  font-size: 16px;
}
.courseList {
  width: 100%;
  padding: 20px;
  height: calc(100vh - 80px);
  overflow: auto;
  .courseItem {
    width: 100%;
    height: 120px;
    background-color: #fff;
    margin-bottom: 10px;
    border-radius: 5px;
    box-shadow: 0 0 10px #ccc;
    padding: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    line-height: 20px;
    .courseTitle {
      color: #333333;
    }
    .courseTime {
      color: #666666;
      font-size: 14px;
    }
  }
}
.van-tag {
  font-size: 10px;
}
.van-tag--success {
  background-color: #1777ff;
}
.van-button--plain.van-button--primary {
  color: #1777ff;
}
.van-button--primary {
  border-color: #1777ff;
}
</style>
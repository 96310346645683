<template>
  <div class="paybody" ref="imageTest">
    <img :src="require(`@/assets/logo.png`)" alt="聚瑞Logo" class="size">
    <span class="fontsize">订单编号：{{'jurui231314783'}}</span>
    <van-cell-group>
      <van-field label="服务名称" :value="parms.product" readonly input-align="right"/>
      <van-field label="服务年限" :value="parms.service" readonly input-align="right"/>
      <van-field label="服务价格" :value="parms.price" readonly input-align="right"/>
      <van-field label="客户姓名" :value="parms.userName" readonly input-align="right"/>
      <van-field label="手机号" :value="parms.phoneNumber" readonly input-align="right"/>
    </van-cell-group>
    <!-- <span class="blue" @click="setCanvas">保存图片到本地>>></span> -->
    <span class="pays">支付完成</span>
  </div>
</template>
<script>
// import html2canvas from 'html2canvas'
export default {
  data(){
    return{
      parms: this.$route.query
    }
  },
  methods: {
    //  async setCanvas () {
    //    console.log('开始绘制')
    //   const canvas = document.createElement('canvas')
    //   // 获取要生成图片的 DOM 元素
    //   let canvasDom = this.$refs.imageTest
    //   // 获取指定的宽高
    //   const width = parseInt(window.getComputedStyle(canvasDom).width)
    //   const height = parseInt(window.getComputedStyle(canvasDom).height)
    //   // 宽高扩大 2 倍 处理图片模糊
    //   canvas.width = width * 2
    //   canvas.height = height * 2
    //   canvas.style.width = width / 2 + 'px'
    //   canvas.style.height = height / 2 + 'px'
    //   const context = canvas.getContext('2d')
    //   context.scale(1, 1)
    //   const options = {
    //     backgroundColor: null,
    //     canvas: canvas,
    //     useCORS: true
    //   }
    //   await html2canvas(canvasDom, options).then( async canvas => {
    //     // 生成图片地址
    //     let link = document.createElement("a")
    //     link.href = canvas.toDataURL('image/png')
    //     link.setAttribute("download","保单.png")
    //     link.style.display = "none"
    //     document.body.appendChild(link)
    //     link.click();
    //   })
    // }
  }
}
</script>
<style scoped>
  .paybody{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 20px;
  }
  .fontsize{
    font-size: 16px;
    margin-bottom: 20px;
  }
  .size{
    width: 200px;
    margin-bottom: 20px;
  }
  .pays{
    font-size: 16px;
    color: cornflowerblue;
    margin-top: 20px;
  }
  .blue{
    font-size: 16px;
    color: cornflowerblue;
    margin-top: 20px;
  }
</style>
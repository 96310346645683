<template>
  <div class="content">
    <p class="title">答题完成</p>
    <p class="score">
      得分:
      <span class="score-value">{{ score }}</span>
    </p>
    <!-- <div style="width: 100%; margin-top: 20px" class="row justify-center">
      <van-button class="btn" type="primary" size="small" @click="seeCource">
        重新观看课程
      </van-button>
    </div> -->
    <div style="width: 100%; margin-top: 20px" class="row justify-center">
      <van-button
        class="btn"
        style="background-color: #ffffff; border-color: #cccccc"
        size="small"
        @click="back"
      >
        返回课程列表
      </van-button>
    </div>
    <!-- <MyVideo ref="myVideo"></MyVideo> -->
  </div>
</template>

<script>
import { examinationScore } from "@/api/oa";
// import MyVideo from "../study/video.vue";
export default {
  components: {
    // MyVideo,
  },
  data() {
    return {
      score: "",
      courseId: "",
    };
  },
  mounted() {
    this.examinationId = this.$route.query.examinationId;
    this.courseId = this.$route.query.courseId;
    this.examinationScoreFun();
  },
  methods: {
    // courseStartFun(courseId) {
    //   courseStart({ courseId });
    // },
    seeCource() {
      // 查看课程
      //   this.$refs.myVideo.onShow(this.courseId);
      //   this.courseStartFun(this.courseId);
      this.$router.push(`/course/study?courseId=${this.courseId}`);
    },
    back() {
      // 返回课程列表
      this.$router.push("/course/study");
    },
    examinationScoreFun() {
      examinationScore({
        examinationId: this.examinationId,
      }).then((res) => {
        this.score = res.data.totalScore;
      });
    },
  },
};
</script>

<style lang="less" scoped>
.content {
  padding: 20px;
  font-size: 16px;
  .title {
    font-size: 20px;
    text-align: center;
    margin: 20px;
    font-weight: 600;
  }
  .score {
    text-align: center;
    .score-value {
      font-weight: 600;
    }
  }
  .btn {
    width: 80%;
    margin: auto;
    background-color: #1777ff;
    border-color: #1777ff;
  }
}
</style>
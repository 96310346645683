<template>
  <van-popup v-model="show" class="content">
    <div
      style="width: 100vw; height: 100vh"
      v-for="(item, index) in playerList"
      :key="index"
      v-show="courseId === item.courseId"
    >
      <van-icon class="close-icon" name="clear" size="30" @click="onClose" />
      <video
        class="video-js"
        :id="'videoPlayer' + item.courseId"
        controls
        preload="auto"
        style="width: 100%; height: 100%"
        data-setup="{}"
        x5-video-orientation="portraint"
        x5-video-player-type="h5"
        :x5-video-player-fullscreen="true"
        ref="videoPlayer"
      >
        <source :src="item.url" type="video/mp4" />
      </video>
    </div>
  </van-popup>
</template>

<script>
import { courseFinish, submitProgress } from "@/api/oa.js";
export default {
  data() {
    return {
      url: "", // http://192.168.1.90:8443/20240104/1742794321574367232-c073cdb8c1dc7577d6b7abbde9cea10a.mp4
      show: false,
      playerList: [],
      courseId: "",
      timer: null,
    };
  },
  mounted() {},
  methods: {
    courseFinishFun() {
      courseFinish({ courseId: this.courseId }).then(() => {
        this.$message.info("该课程已经学习完成");
      });
    },
    submitProgressFun(s) {
      submitProgress({ courseId: this.courseId, duration: s });
    },
    onShow(e) {
      this.show = true;
      this.courseId = e.id;
      const obj = {
        courseId: e.id,
        url: e.videoUrl,
      };
      let flag = true;
      this.playerList.forEach((res) => {
        if (res.courseId === e.id) {
          flag = false;
        }
      });
      if (flag) {
        this.playerList.push(obj);
      }
      //   console.log(this.url, "============url");
      this.$nextTick(() => {
        const that = this;
        // console.log(
        //   "videoPlayer" + e.id,
        //   '========"videoPlayer" + this.courseId'
        // );
        this.player = videojs("videoPlayer" + e.id);

        this.setVideTime(e.lastTimeDuration || 0);
        this.timer = setInterval(() => {
          this.getVideoProgress();
        }, 1000 * 3);

        // 监听暂停状态
        this.player.on("pause", (event) => {
          clearInterval(this.timer);
          this.getVideoProgress();
        });

        // 监听播放状态
        this.player.on("play", function (event) {
          that.videoPlay();
        });

        // 监听视频播放结束
        this.player.on("ended", function (event) {
          that.courseFinishFun();
          clearInterval(this.timer);
          that.videoEned();
        });

        // 监听事件更新
        this.player.on("timeupdate", () => {
          this.getVideoCurrentTime();
        });
      });
    },
    onClose() {
      //   this.player.dispose();
      clearInterval(this.timer);
      this.show = false;
    },

    // 获取当前视频播放进度
    getVideoProgress() {
      console.log(`视频播放进度为${this.player.currentTime()}`);
      this.submitProgressFun(Math.floor(this.player.currentTime()));
    },
    // 获取当前视频时间
    getVideoCurrentTime() {
      //   console.log("========获取当前视频时间=======");
      const video = document.querySelector("video");
      this.currentTime = video.currentTime;
    },
    // 设置视频当前播放时间
    setVideTime(time) {
      console.log("执行重置时间");
      this.player.currentTime(time);
    },

    videoPlay() {
      // 视频播放函数
      console.log("视频播放");
    },
    videoEned() {
      // 视频播放暂停
      console.log("视频暂停");
    },
    // 注册禁止播放按钮事件
    registDrug() {
      if (this.isDrag) {
        //启用拖动
        document.querySelector(".vjs-progress-control").style.pointerEvents =
          "auto";
      } else {
        //禁用拖动
        document.querySelector(".vjs-progress-control").style.pointerEvents =
          "none";
      }
    },
  },
};
</script>

<style lang="less" scoped>
.content {
  //   padding: 20px;
  font-size: 16px;
  //   position: relative;
  .ctrl {
    position: fixed;
    border: 1px solid red;
    z-index: 9999;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    color: #ffffff;
  }
  :deep(.vjs-progress-control) {
    position: relative;
    // visibility: hidden;
    pointer-events: none !important;
  }
  .close-icon {
    position: absolute;
    right: 10px;
    top: 10px;
    z-index: 9999;
    color: #ffffff;
  }
}
</style>
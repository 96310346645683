<template>
  <div class="lafbody">
    <h2 class="laftitle">隐私政策</h2>
    <p class="align-right">发布时间：2021-11-18</p>
    <p class="lafp">
      在此特别提醒您 (用户) 在注册成为用户之前，请认真阅读本《隐私协议》 (以下简称“协议”)
      ，确保您充分理解本协议中各条款。请您审慎阅读并选择接受或不接受本协议。我们努力用通俗易懂、简明扼要的文字表达，并对本政策中与您的权益存在重大关系的条款，采用粗体字进行标注以提示您注意。</p>
    <p class="lafp">
      您的注册、登录、使用等行为将视为对本协议的接受并同意接受本协议各项条款的约束。
    </p>
    <p class="lafp">本协议约定 车友淘小程序 (以下简称“我们”或“本公司”)
      与用户之间关于软件或服务的权利义务。用户”是指注册、登录、使用本公司产品或服务的主体。出于业务、技术的发展以及更好提供服务的目的我们会适时对本隐私协议进行修订，新修订的隐私协议发布之后将完全代替之前版本的隐私协议，请您及时了解查阅相关内容，恕不再另行通知。如果您对修改后的隐私协议条不能接受，请立即停止使用本公司提供的产品和服务，用户继续使用本公司产品或服务将被视为接受修改后的协议。
    </p>
    <p class="lafp">
      本隐私协议将帮助您了解:
    </p>
    <p class="lafp">我们完全遵循隐私协议来收集和使用您的有限的信息其目的为了帮助您获得更好的服务。</p>
    <p class="lafp">当您使用或开启相关功能或接受服务时，为确保功能服务得以实施，我们会收集必要信息。除非完全必要否则大部分情况下您无需提供这部分信息。</p>
    <p class="lafp">您可以选择账户注册方式登录应用，如果没有登录，则应用本身会使用设备标识符信息来建立对您的账户关联，以便于及时提供相应服务。</p>
    <p class="lafp">除非经过您的允许，否则应用不会开启非必要权限。即便开启了经过您授权的非必要权限，您也可以随时在本应用或设备操作系统中停止授权。</p>
    <p class="lafp">本公司竭力提供安全、绿色、稳定的应用，如您对本隐私政策有任何疑问，也可以随时联系我们以获得及时的解答。</p>
    <p class="lafp">以下我们将全面介绍我们是如何对本应用可能涉及到的个人信息进行收集和处理的:</p>
    <p class="lafp">1 个人信息的收集和使用</p>
    <p class="lafp">2 设备信息标识符的使用</p>
    <p class="lafp">3 个人信息的共享、转让或披露</p>
    <p class="lafp">4 个人信息的存储</p>
    <p class="lafp">5 个人信息的保护</p>
    <p class="lafp">6 个人信息的管理</p>
    <p class="lafp">7 隐私政策的修订和通知
    </p>
    <h3 class="lafxuzhi">1 个人信息的收集和使用</h3>

    <p class="lafsubTitle">1.1 注册和登录</p>
    <p class="lafp">您可以通过应用内账户注册的方式，利用手机号创建账户，此过程中所需要完善的信息均为帮助您完成注册。您可以选择不提交或修改非必要的信息，例如头像、昵称等。</p>
    <p class="lafp">您也可以在应用当中使用第三方帐号登录并使用本应用，在获得您的授权之后，应用将经过您授权的信息与本应用进行绑定，方便您可以直接登录并使用本产品和服务。</p>
    <p class="lafp">在部分产品中由于功能需要可能会采取设备信息标识符为依据，帮助您建立默认账户。您有权选择修改、删除或重新编辑这部分内容、也可以随时通过“账户注销”方式彻底删除这些信息。</p>
    <p class="lafsubTitle">1.2 信息提交与发布</p>
    <p class="lafp">
      使用我们的应用过程当中您可能会需要提交或发布信息，包括但不限于图片、视频、音频等，在此之前我们会向您申请授权相机、照片、麦克风、摄像头、存储卡读写等权限。您如果拒绝授权提供，将无法使用此功能，但不影响您正常使用本应用的其他功能
    </p>
    <p class="lafsubTitle">1.3 设备与日志信息</p>
    <p class="lafp">请您知晓，在使用本公司产品过程当中，出于持续改善产品服务的目的，我们可能会收集您使用的设备、 日志信息。</p>
    <p class="lafsubTitle">以下是可能会收集的部分或全部信息:</p>
    <p class="lafp">硬件型号、操作系统版本号、国际移动设备识别码、唯一设备标识符、网络设备硬件地址、IP 地址、WLAN
      接入点、蓝牙、基站、软件版本号、网络接入方式、类型、状态、网络质量数据、操作、使用、服务日志等同时为了确保产品的正常运行，我们还可能会收集以下部分或全部信息:进程信息、应用程序的总体运行、使用情况与频率、应用崩溃情况、总体安装使用情况、性能数据、应用来源。在未获得您的同意和授权之前，我们不会超出上述应用范围来使用这部分信息。
    </p>
    <p class="lafsubTitle">1.4 其他可能收集的信息</p>
    <p class="lafp">在应用使用的过程当中，随着产品的更新和业务的发展，以下是部分可能需要申请的权限。请您知晓，在任何情况下，我们都会提前向您提出权限的申请，您可以拒绝此权限申请，拒绝该权限申请不影响您使用产品的其他功能。
    </p>
    <p class="lafp">为了进一步提供便捷的登录服务，以及支持应用使用分析与统计，我们可能会需要向您申请点号识别码权限。</p>
    <p class="lafp">针对部分机型的数据备份需求，我们可能会向用户申请应用内安装其他应用的权限。出于提供涉及日期的任务备忘功能，我们可能会向用户申请日历读写的权限。</p>
    <p class="lafsubTitle">1.5 收集、使用个人信息的变更</p>
    <p class="lafp">请您知晓，随着我们产品的更新与业务的发展，上述个人信息、权限的需求可能会出现变更。我们将及时更新这部分内容以供您及时了解和参考。</p>
    <p class="lafsubTitle">1.6 依法豁免征得同意收集和使用的个人信息</p>
    <p class="lafp">
      请您理解，在下列情形中，根据法律法规及相关国家标准，我们收集和使用您的个人信息无需征得您的授权同意：国防安全直接相关的；与国家安全、与公共安全公共卫生、重大公共利益直接相关的；与犯罪侦查、起诉、审判和判决执行等直接相关的;出于维护个人信息主体或其他个人的生命、财产等重大法权益但又很难得到本人同意的;收集的您的个人信息是您自行向社会公众公开的;从合法公开披露的信息中收集的您的个人信息的，如合法的新闻报道、政府信息公开等渠道;根据您的要求签订或履行合同所必需的;用于维护软件及相关服务的安全稳定运行所必需的，例如发现、处置软件及相关服务的故障;为合法的新闻报道所必需的;学术研究机构基于公共利益开展统计或学术研究所必要，且对外提供学术研究或描述的结果时，对结果中所包含的个人信息进行去标识化处理的;法律法规规定的其他情形
    </p>
    <h3 class="lafxuzhi">2 设备信息标识符的使用</h3>
    <p class="lafp">
      设备信息标识符可以作为移动设备的识别标识，是目前互联网当中被广泛、普遍应用的技术。当您使用本公司应用且未登录的时候，应用可能会借助该设备信息标识符给您建立一个账户。该账户建立的目的在于方便您的访问、使用以及记录您的订单和消费情况，也是您获得后续服务的关键信息。
    </p>
    <p class="lafp">本公司在此承诺，不会将通过设备信息标识符获得的信息，用于上述应用之外的任何其他用途。</p>
    <p class="lafp">为进一步说明设备信息标识符技术的应用，请仔细阅读以下说明内容:</p>
    <p class="lafsubTitle">2.1 帮助您获得更好的服务体验</p>
    <p class="lafp">
      传统登录方式将需要您主动填写个人信息，采用设备信息标识符进行登录，则完全无需填写任何信息，从而提升了登录和服务的响应速度。即便如此，您依然可以选择编辑、修改、删除那些利用设备信息标识符技术自动完成的信息。</p>
    <p class="lafsubTitle">2.2 为服务过程提供信息记录</p>
    <p class="lafp">利用设备信息标识符进行自动登录之后，您的订单、消费以及会员资格等信息都会自动记录到该账户下，便于您可以随时浏览和查询，以及以此作为获得相应服务的主体资格。同时也可以有效避免账户被盗用的风险 </p>
    <p class="lafsubTitle">2.3 如何退出或删除设备信息标识符</p>
    <p class="lafp">
      默认采用设备信息标识符登录情况下，您依然可以选择退出账户。请注意，我们不会将使用本设备信息标识符的技术以及获得的信息，用于本公司应用之外的任何其他用途，除非得到您的同意和授权。如果您希望进一步删除账户，可以通过账户注销流程进行注销
    </p>
    <h3 class="lafxuzhi">3 个人信息的共享、转让或披露</h3>
    <p class="lafsubTitle">3.1 共享</p>
    <p class="lafsubTitle">3.1.1 共享原则</p>
    <p class="lafp">
      除非得到您的同意和授权，本公司不会对您的个人信息进行共享。本公司承诺，任何情况下，未经您的同意和授权，都不会以任何方式共享您的个人信息。即便得到您的同意和授权，在正式共享您的个人信息之前，我们也将对共享的个人信息进行审慎、严谨的评估，以确保不会造成敏感信息的泄漏。
    </p>
    <p class="lafp">经过您的同意和授权后，您的个人信息将在合理、合法、正当的前提下进行共享。同时，必要情况下，您的个人信息中的敏感部分也将被完全去除或重新处理。</p>
    <p class="lafp">
      部分情况下，为了更好地提供用户服务或改善现有服务为目的，确实需要共享的，您的个人信息中的敏感部分将被完全去除或重新处理，以达到无法还原和识别出该信息的自然人主体的目的。除此之外的任何个人信息的共享，都将需要得到您的同意和授权。
    </p>
    <p class="lafp">我们将对将合规的第三方进行长期、审慎、严谨的评估，确保在完全符合法律法规前提下进行对这部分信息进行共享。我们对于第三方提供的软件工具开发包(SDK)、应用程序接口
      (API)都会进行严格的、全面的测试和评估， 以保护用户个人信息的安全</p>
    <p class="lafsubTitle">3.1.2 共享信息实现的功能</p>
    <p class="lafp">
      a.在使用本公司产品过程当中，由于本公司、第三方功能提供商、系统服务提供商等联合为您提供相应服务,为了更好地提供用户服务或改善现有服务，我们可能会共享部分必须的信息，但不包含个人信息中的敏感部分。这些共享信息仅用于对产品使用的综合统计、用户行为的偏好分析，从而更好地为改善产品、提高用户体验、推荐相应内容提供依据。
    </p>
    <p class="lafp">b.当您使用第三方账户登录本公司应用时，我们将获得来自第三方提供的个人信息，包括用户昵称、头像等经过您授权共享给我们使用的信息。除此之外，我们不会进一步获取更多的信息。</p>
    <p class="lafp">
      c.除非必要，否则本公司应用不需要您提供地理位置相应权限。本公司承诺，在需要您的地理位置权限时候会提前征求您的同意和授权，您可以随时拒绝提供此权限，拒绝提供该权限并不会影响涉及该功能之外的其他应用功能的使用</p>
    <p class="lafp">
      d.消费和支付功能:支付功能是由与本公司合作的第三方支付机构向您提供服务。第三方支付机构在提供该服务的时候，可能会收集您的姓名、银行卡类型以及卡号、有效期及手机号码。这些敏感信息是支付过程必需的信息，拒绝提供这部分信息将导致您无法使用相应支付功能。
    </p>
    <p class="lafp">e.为了更好地与您使用的终端机型进行适配，我们可能会通过 SDK 等技术与手机厂商共享手机型号、版本以及相关设备信息。</p>
    <p class="lafsubTitle">3.2 转让</p>
    <p class="lafp">
      本公司不会转让您的个人信息给任何第三方，除非征得您的同意和授权。特殊情况下，例如本公司进行合并收购、资产转让等，您的个人信息可能因此而被转移在发生前述变更时，我们将按照法律法规及不低于本隐私政策所载明的安全标准下，要求继受方保护您的个人信息，否则我们将要求继受方重新征得您的同意和授权。
    </p>

    <p class="lafsubTitle">3.3 公开披露</p>
    <p class="lafp">
      在未得到您的同意和授权之前，我们不会公开披露您的个人信息。在获得您的同意和授权之后，我们也将在遵循国家法律法规的前提下进行展示，您的个人信息中的敏感部分将被去除或重新处理，以达到无法还原和识别出该信息的自然人主体的目的。个人信息的公开披露将采用符合行业内标准的安全保护措施进行处理
    </p>
    <p class="lafsubTitle">3.4 依法披露</p>
    <p class="lafp">根据法律法规及国家标准，在以下情形中，我们对您个人信息的共享、转让、披露无需征得您的同意和授权:</p>
    <p class="lafp">a.与国家安全、国防安全直接相关的; </p>
    <p class="lafp">b.与公共安全、公共卫生、重大公共利益直接相关的; </p>
    <p class="lafp">c.与犯罪侦查、起诉、审判和判决执行等直接相关的;</p>
    <p class="lafp">d.出于维护您或其他个人的生命、财产等重大合法权益但又很难得到本人同意的; </p>
    <p class="lafp">e.您自行向社会公众公开的个人信息; </p>
    <p class="lafp">f.从合法公开披露的信息中收集个人信息的，如合法的新闻报道、政府信息公开等渠道。</p>
    <h3 class="lafxuzhi">4 个人信息的存储</h3>
    <p class="lafsubTitle">4.1 存储地点</p>
    <p class="lafp"> 我们依照国家法律法规的规定，将在境内运营过程中收集和产生的您的个人信息存储于中华人民共和国境内。目前，我们不会将上述信息传输至境外，如果我们向境外传输，我们将会遵循相关国家规定或者征求您的同意。
    </p>
    <p class="lafsubTitle">4.2 存储期限</p>
    <p class="lafp">在使用本应用功能以及服务期间我们将对个人信息进行存储。超出存储期限的情况下，您的个人信息将可能被删除或匿名化处理，除非法律法规另有规定的除外</p>
    <h3 class="lafxuzhi">5 个人信息的保护</h3>
    <p class="lafp">a.
      在注重信息安全的前提下，我们竭力采取合理的技术和管理措施来保护您的个人信息。同时，我们也配备了专业的技术领导小组，持续改进我们的信息安全措施提升技术管理水平，以力求最大化地确保您的个人信息得以妥善保管。</p>
    <p class="lafp">b. 出于保护信息安全的目的，您的个人信息将被加密或匿名化处理，或者采用业内通用的、合理的、合乎法律规范的技术手段进行处理。</p>
    <p class="lafp">c. 本公司内部在技术领导小组的带领下，提供了完善的信息安全监督机制，包括数据的使用、访问、共享等都需要经过严格授权</p>
    <p class="lafp">d.
      尽管已经采取上述合理有效措施，并已经遵守了相关法律规定要求的标准，但请您理解，由于技术的限制以及可能存在的各种恶意手段，在互联网行业，即便竭尽所能加强安全措施，也不可能始终保证信息百分之百的安全。</p>
    <p class="lafp">e. 您接受并理解，使用本公司应用的过程当中，有可能出现意外的、不可控的信息安全问题。我们同样建议您采取积极的措施保护个人信息的安全，例如使用复杂的密码、定期修改密码，且不将个人信息透露给第三方等。
    </p>
    <p class="lafp">f. 在上述合理有效措施的基础上，我们进一步建立信息安全处理预案， 以确保在出现信息安全问题时启动应急预案，努力阻止这些安全事件的影响和后果扩大。</p>
    <p class="lafp">g. 由于您个人原因造成的信息安全问题，特别是脱离本应用使用环境下造成的问题，不在本公司个人信息安全保护范畴内。</p>
    <h3 class="lafxuzhi">6 个人信息的管理</h3>
    <p class="lafsubTitle">6.1 自主管理</p>
    <p class="lafp">您的个人信息完全归属您本人所有，本公司全力保障您个人对提交给本公司的个人信息的管理权限，您可以随时在本应用中修改您的个人信息。在您修改个人信息的时候，应当遵循国家法律法规的要求。</p>
    <p class="lafsubTitle">6.2 权限授权</p>
    <p class="lafp">
      请注意，在使用本公司应用过程当中，我们会明确公示所需要申请的必需权限，也会明确告知本隐私协议的全部内容，除非得到您的授权，否则这些权限将不会被开启。同时，您还可以在设备操作系统当中关闭这些权限。对于权限，例如 GPS
      地理位置、通讯录、摄像头麦克风、相册等，在应用中如果属于非必需的权限，关闭或拒绝授权并不会影响应用的使用。</p>
    <p class="lafp">
      请您知晓，必要权限会影响应用的主要使用，如果您拒绝对必要权限进行授权或在设备操作系统当中关闭必须授权，都将影响您对应用的使用在使用本应用时，我们会明确向您申请必要权限的授权，并且仅在您同意并授权之后，才会开启这部分权限。</p>
    <h3 class="lafxuzhi">7 隐私政策的修订和通知</h3>
    <p class="lafp">出于业务、技术的发展以及更好提供服务的目的，我们会适时对本隐私协议进行修订，新修订的隐私协议发布之后将完全代替之前版本的隐私协议，请您及时了解查阅相关内容，恕不另行通知。</p>
    <h3 class="lafxuzhi">8 争议解决</h3>
    <p class="lafp">因本协议而发生的纠纷，您可选择与我们协商解决，也可选择向深圳市人民法院提起诉讼解决。</p>
  </div>
</template>

<script>
export default {
  data() {
    return {}
  }
}
</script>

<style lang="less" scoped>
h2,
h3,
p {
  margin: 0;
  white-space: normal;
  text-align: justify;
}

i {
  font-style: normal;
}

.lafp {
  font-size: 16px;
  line-height: 32px;
  font-weight: 400;
  color: #666;
  padding-bottom: 20px;
  text-indent: 1rem;
  // padding-left: 14px;

  span {
    display: inline-block;
    margin-left: 14px;
  }
}

.lafbody {
  width: 100%;
  padding: 0.4rem 0.2rem;
  margin: 0 auto;
  height: 100vh;
  overflow: scroll;
}

.laftitle {
  // color: #e60012;
  font-weight: 700;
  font-size: 26px;
  text-align: center;
  // padding-bottom: 20px;
}

.lafxuzhi {
  font-weight: 700;
  color: #333;
  font-size: 20px;
  padding-bottom: 10px;
}

.lafsubTitle {
  font-size: 16px;
  color: #666;
}

.align-right {
  text-align: center;
  font-size: 16px;
  line-height: 32px;
  font-weight: 400;
  color: #666;
  margin-bottom: 20px;
}
</style>

<template>
  <div class="paybody">
    <img :src="require(`@/assets/logo.png`)" alt="聚瑞Logo" class="size">
    <van-cell-group>
      <van-field label="服务名称" :value="parms.productName" readonly input-align="right"/>
      <van-field v-if="parms.servicePeriod!=='undefined'?true:false" label="服务年限" :value="parms.servicePeriod+'年'" readonly input-align="right"/>
      <van-field label="服务价格" :value="parms.amount+'元'" readonly input-align="right"/>
      <van-field label="客户姓名" :value="parms.customerName" readonly input-align="right"/>
      <van-field label="手机号" :value="parms.contactPhone" readonly input-align="right"/>
    </van-cell-group>
    <div style="margin: 16px;width:300px">
      <van-button round block type="info" @click="onSubmit">前往支付</van-button>
    </div>
  </div>
</template>

<script>
import { paysubmit } from '@/api/juruipay'
  export default {
    data() {
      return {
        parms: this.$route.query,
        payWay: 'wxQR'
      }
    },
    created() {
      if (/MicroMessenger/.test(window.navigator.userAgent)) {
        // 微信客户端
        this.payWay = 'wxQR'
      } else if (/AlipayClient/.test(window.navigator.userAgent)) {
        // 支付宝客户端
        this.payWay = 'alipayQR'
      } else {
        // 其他浏览器
        this.payWay = ''
      }

    },
    methods: {
      onSubmit() {
        const data = {...this.parms}
        data.payWay = this.payWay
        data.amount =  Number(this.parms.amount)
        data.productId = Number(this.parms.productId)
        data.userId = Number(this.parms.userId)
        if(this.parms.servicePeriod!=='undefined'){
          data.servicePeriod = Number(this.parms.servicePeriod)
        } else {
          delete data.servicePeriod
        }
        paysubmit(data).then(res => {
          if(res.success===false) {
            this.$toast.fail(res.respMag)
          } else {
            window.location.href = res.content.codeUrl
          }
        })
      }
    }
  }
</script>

<style scoped>
  .paybody{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 20px;
  }
  .size{
    width: 200px;
    margin-bottom: 20px;
  }
</style>

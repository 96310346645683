<template>
  <div class="content">
    <div
      v-for="(item, index) in qList"
      :key="index"
      v-show="currentIndex === index"
    >
      <p class="questionTitle">
        {{ index + 1 }}/{{ qList.length }} {{ qType[item.type] }}
      </p>
      <div class="title">
        <p>题目:{{ item.question }}</p>
      </div>
      <div class="option">
        <div
          class="option-item"
          v-for="(it, i) in item.option"
          :key="i"
          @click="selectValue(it, item.type)"
        >
          {{ it.option }}:{{ it.title }}
          <van-icon
            name="success"
            class="icon"
            v-if="!isCheck && currentValue.includes(it.option)"
          />
          <img :src="trueIcon" class="icon" v-if="isCheck && it.isTrue" />
          <img
            :src="falseIcon"
            class="icon"
            v-if="isCheck && !it.isTrue && currentValue.includes(it.option)"
          />
        </div>
      </div>
      <div
        style="width: 100%; margin-top: 20px"
        class="row justify-center"
        v-if="!isCheck"
      >
        <van-button class="btn" type="primary" size="small" @click="confirm">
          确认
        </van-button>
      </div>
      <div class="analysis" v-if="isCheck">
        <p>试题解析</p>
        <div>
          <p style="color: #1777ff; font-size: 14px" v-if="isTrue()">
            <van-icon name="success" class="icon" color="#1777ff" />
            回答正确
          </p>
          <p style="color: red; font-size: 14px" v-else>
            <van-icon name="cross" class="icon" color="red" />
            回答错误
          </p>
          <div style="text-align: center" class="row justify-around">
            <div>
              <p>正确答案</p>
              <p style="font-weight: 600">{{ rights.join("、") }}</p>
            </div>
            <div>
              <p>我的答案</p>
              <p style="font-weight: 600">{{ currentValue.join("、") }}</p>
            </div>
          </div>
        </div>
      </div>
      <div class="analysis" v-if="isCheck">
        <p class="analysis-p">
          {{ qList[currentIndex].analysis }}
        </p>
      </div>
    </div>
    <div
      style="width: 100%; margin-top: 20px"
      class="row justify-center"
      v-if="isCheck"
    >
      <van-button
        class="btn"
        type="primary"
        size="small"
        @click="next"
        v-if="currentIndex != qList.length - 1"
      >
        下一题
      </van-button>
      <van-button
        class="btn"
        type="primary"
        size="small"
        @click="queryResult"
        v-else
      >
        查看结果
      </van-button>
    </div>
  </div>
</template>

<script>
import { questionList, submitQuestionAnswer } from "@/api/oa";
export default {
  data() {
    return {
      qType: {
        1: "单选题",
        2: "多选题",
      },
      currentIndex: 0, // 当前题目下标
      trueIcon: require("@/assets/true.png"),
      falseIcon: require("@/assets/false.png"),
      qList: [],
      currentValue: [], // 当前题目选择答案
      isCheck: false,
      rights: [], // 正确答案
    };
  },
  mounted() {
    this.examinationId = this.$route.query.examinationId;
    this.courseId = this.$route.query.courseId;
    if (this.examinationId) {
      this.questionListFun();
    }
  },
  methods: {
    isTrue() {
      console.log(this.currentValue, this.rights);
      return this.currentValue.join("") === this.rights.join("");
    },
    submitQuestionAnswerFun() {
      submitQuestionAnswer({
        answer: this.currentValue,
        examinationId: this.examinationId,
        questionId: this.qList[this.currentIndex].id,
      });
    },
    questionListFun() {
      questionList({ examinationId: this.examinationId }).then((res) => {
        this.qList = res.data;
        this.qList.forEach((q) => {
          let rights = q.rightOption.map((r) => r.option).sort();
          q.option.forEach((item) => {
            if (rights.includes(item.option)) {
              item.isTrue = true;
            }
          });
        });
        console.log(this.qList, "======qList");
        this.getRight();
      });
    },
    next() {
      this.isCheck = false;
      this.currentIndex++;
      this.currentValue = [];
      this.getRight();
    },
    getRight() {
      this.rights = this.qList[this.currentIndex].rightOption
        .map((r) => r.option)
        .sort();
      console.log(this.rights, "======rights");
    },
    queryResult() {
      this.$router.push({
        path: `/course/result?examinationId=${this.examinationId}&courseId=${this.courseId}`,
      });
    },
    confirm() {
      if (!this.currentValue || !this.currentValue.length) {
        return;
      }
      //   console.log(this.currentValue);
      this.isCheck = true;
      //   this.submitQuestionAnswerFun()
    },
    selectValue(item, type) {
      if (this.isCheck) return;
      if (type == 1) {
        this.currentValue = [...item.option];
      } else {
        if (!this.currentValue.includes(item.option)) {
          this.currentValue.push(item.option);
        } else {
          this.currentValue.splice(this.currentValue.indexOf(item.option), 1);
        }
      }
      this.currentValue = this.currentValue.sort();
    },
    submit() {
      this.$router.push({ path: "/course/result" });
    },
  },
};
</script>

<style lang="less" scoped>
.content {
  padding: 20px;
  font-size: 16px;
  color: #333333;
  .questionTitle {
    font-size: 18px;
    font-weight: 600;
  }
  .title {
    // border: 1px solid #eeeeee;
    padding: 10px 5px;
    margin: 10px auto;
    font-weight: 600;
  }
  .option {
    .option-item {
      border: 1px solid #eeeeee;
      padding: 5px;
      margin: 10px auto;
      position: relative;
      .icon {
        position: absolute;
        top: 5px;
        right: 10px;
        width: 30px;
        height: 30px;
      }
    }
  }
  .btn {
    width: 80%;
    margin: auto;
    background-color: #1777ff;
    border-color: #1777ff;
  }
  .analysis {
    border: 1px solid #eeeeee;
    border-radius: 5px;
    padding: 5px;
    margin-top: 20px;
    color: #666666;
    font-size: 14px;
    line-height: 20px;
    .analysis-p {
      text-indent: 2em;
    }
  }
}
</style>
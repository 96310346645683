<template>
  <div class="content color3">
    <div class="row justify-center font-12">
      {{ examination.examinationName }}
    </div>
    <div class="row justify-around examinationInfo">
      <div class="row justify-center">
        题目数量：<span class="value">{{ examination.questionCount }}</span>
      </div>
      <div class="row justify-center">
        总分数：<span class="value">{{ examination.fullScore }}</span>
      </div>
    </div>
    <div class="row justify-between lastScore">
      <span>最高得分</span>
      <span class="value">{{ examination.maxScore }}</span>
    </div>
    <div style="width: 100%" class="row justify-center">
      <van-button
        class="btn"
        type="primary"
        size="small"
        @click="goto(`/course/question?examinationId=${examinationId}&courseId=${courseId}`)"
      >
        开始答题
      </van-button>
    </div>
  </div>
</template>

<script>
import { examinationInfo } from "@/api/oa";
export default {
  data() {
    return {
      examination: {},
      examinationId: "",
      courseId: ''
    };
  },
  mounted() {
    this.examinationId = this.$route.query.examinationId;
    this.courseId = this.$route.query.courseId;
    if (this.examinationId) {
      this.examinationInfoFun();
    }
  },
  methods: {
    goto(url) {
      url && this.$router.push({ path: url });
    },
    examinationInfoFun() {
      examinationInfo({ examinationId: this.examinationId }).then((res) => {
        this.examination = res.data;
        // console.log(res.data);
      });
    },
  },
};
</script>

<style lang="less" scoped>
.content {
  font-size: 16px;
  padding: 20px;
  .examinationInfo {
    // line-height: 25px;
    margin-top: 20px;
  }
  .value {
    font-size: 18px;
    font-weight: 600;
  }
  .lastScore {
    border: 1px solid #cccccc;
    width: 80%;
    margin: 20px auto;
    padding: 5px;
    border-radius: 5px;
  }
  .btn {
    width: 50%;
    margin: auto;
    background-color: #1777ff;
    border-color: #1777ff;
  }
}
</style>
<!--
 * @Description: 
 * @Author: 周强
 * @Date: 2022-01-04 11:27:35
 * @LastEditTime: 2022-01-11 16:16:22
 * @LastEditors:  
-->
<template>
  <div class="spring">
    <img :src="require('@/assets/spring/chunjie.jpg')" alt="" class="bg">
    <div class="rule">
      <div class="ruleimg">活动规则</div>
      <div class="rulefont">
        <span>1、礼品券仅限中国大陆的用户使用，可在规定时间内兑换聚瑞相应的指定兑换商品；</span><br />
        <span>2、礼品券兑换的有效时间以聚瑞系统时间为准，所有日期按自然日计算；</span><br />
        <span>3、礼品券的使用支配权归用户所有，可自己或转赠他人使用；</span><br />
        <span>4、使用礼品券时，礼品券面额不能叠加或拆分，仅限兑换该礼品券指定兑换商品；</span><br />
        <span>5、用户使用礼品券兑换指定商品时无需支付任何费用；</span><br />
        <span>6、如兑换过程中出现任何问题请联系客服；</span><br />
        <span>7、兑换日期：2022年01月10日至2022年01月17日；</span><br />
      </div>
      <img :src="require('@/assets/spring/spring.png')" alt="" class="springimg">
      <div class="ruleimg mt10">礼券兑换</div>
      <div class="rulefont">
        <van-form @submit="onSubmit" @failed="failed" class="form" v-if="shows">
          <van-field v-model="cardNo" name="cardNo" label="礼券号码" placeholder="请输入礼券号码" input-align="left" :rules="[{ required: true}]" />
          <van-field v-model="cardPwd" name="cardPwd" label="礼券密码" placeholder="请输入礼券密码" input-align="left" :rules="[{ required: true}]" />
          <van-field :center="true" v-model="verCode" name="verCode" placeholder="请输入验证码" label="验证码" input-align="left" :rules="[{ required: true}]">
            <template #button>
              <img :src="codeSrc" class="codeimg" @click="getVerifyCode" />
            </template>
          </van-field>
          <div style="margin: 16px;">
            <van-button round block type="info" native-type="submit">下一步</van-button>
          </div>
        </van-form>
        <van-form @submit="onSubmit2" class="form" v-if="!shows">
          <!-- <div class="title">设置服务信息</div> -->
          <van-field v-model="receiver" name="receiver" label="收货人" placeholder="请输入收货人" input-align="left" :rules="[{ required: true}]" />
          <van-field v-model="mobile" name="mobile" label="手机号" placeholder="请输入手机号码" input-align="left" type="tel" :rules="[{ required: true, pattern: /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/}]" />
          <van-field v-model="fieldValue" name="fieldValue" is-link readonly label="地区" placeholder="请选择所在地区" @click="addressShow = true" />
          <van-popup v-model="addressShow" round position="bottom">
            <van-cascader :field-names="fieldNames" v-model="cascaderValue" title="请选择所在地区" :options="options" @close="addressShow = false" @finish="onFinish" />
          </van-popup>
          <van-field v-model="address" name="address" label="收货地址" placeholder="请输入收货地址" input-align="left" :rules="[{ required: true}]" />
          <div style="margin: 16px;">
            <van-button round block type="info" native-type="submit">提交</van-button>
          </div>
        </van-form>
      </div>
      <div class="title">
        <span>智慧车管</span>
        <span>聚瑞云控</span>
      </div>
    </div>
    <van-overlay :show="show" @click="show = false" class-name="bgs">
      <div class="wrapper">
        <div class="tip">
          <span>恭喜您！兑换成功！礼品将于7个工作日内邮寄，请留意快递信息！</span>
          <div class="btsize"><van-button round block type="info" size="small" @click="show = false" >确认</van-button></div>
        </div>
      </div>
    </van-overlay>
  </div>
</template>
<script>
  import { getCode, exchange, setShipAddress, getCityCascader } from '@/api/springFestival'
  export default {
    data() {
      return {
        cascaderValue: '',
        fieldValue: '',
        addressShow: false,
        cardNo: '',
        cardPwd: '',
        verCode: '',
        codeSrc: '',
        receiver: '',
        address: '',
        mobile: '',
        options: [],
        fieldNames: {
          text: 'label',
          value: 'label',
          children: 'children',
        },
        uuid: '',
        show: true,
        shows: true
      }
    },
    created() {
      getCityCascader().then(res => {
        this.options = this.delChildren(res.content)
      })
      // console.log(this.options)
      this.show = false
      this.shows = true
      getCode().then(res => {
        this.codeSrc = res.content.code
        this.uuid = res.content.uuid
      })
    },
    methods: {
      // 验证不通过
      failed(value){
        console.log(value)
        this.getVerifyCode()
      },
      // 遍历去掉children为空的数据
      delChildren(arr) {
        for (let i = 0; i < arr.length; i++) {
          if (arr[i].children.length === 0 || arr[i].children === null) {
            arr[i].children = undefined
          } else {
            this.delChildren(arr[i].children)
          }
        }
        return arr
      },
      // 全部选项选择完毕后，会触发 finish 事件
      onFinish({ selectedOptions }) {
        this.addressShow = false;
        this.fieldValue = selectedOptions.map((option) => option.label).join('/');
      },
      // 下一步
      onSubmit(values) {
        console.log(values)
        const params = {
          cardNo: values.cardNo,
          cardPwd: values.cardPwd,
          verCode: values.verCode,
          uuid: this.uuid,
          giftType: 4,
          type: 3
        }
        exchange(params).then(res => {
          if (res.success === true) {
            this.shows = false
          } else {
            this.$toast.fail(res.respMag)
            this.getVerifyCode()
            this.cardNo = ''
            this.cardPwd = ''
            this.receiver = ''
            this.address = ''
            this.mobile = ''
            this.cascaderValue = ''
            this.fieldValue = ''
          }
        })
      },
      //  提交
      onSubmit2(values) {
        const params = { cardNo: this.cardNo, type: 3, ...values }
        params.province = values.fieldValue.split('/')[0]
        params.city = values.fieldValue.split('/')[1]
        params.area = values.fieldValue.split('/')[2]
        delete params.fieldValue
        setShipAddress(params).then(res => {
          if (res.success === true) {
            this.getVerifyCode()
            this.cardNo = ''
            this.cardPwd = ''
            this.receiver = ''
            this.address = ''
            this.mobile = ''
            this.cascaderValue = ''
            this.fieldValue = ''
            this.shows = true
            this.show = true
          } else {
            this.$toast.success(res.respMag)
          }
        })
      },
      // 获取验证码
      getVerifyCode() {
        getCode().then(res => {
          this.codeSrc = res.content.code
          this.uuid = res.content.uuid
          this.verCode = ''
        })
      }
    }
  }
</script>

<style scoped>
  .btsize{
    width: 150px;
    /* height: 20px; */
  }
  .tip {
    width: 300px;
    height: 150px;
    border-radius: 15px;
    padding: 20px 20px 10px 20px;
    font-size: 16px;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: #fff;
    justify-content: space-between;
  }
  .tip span {
    line-height: 30px;
  }
  .title {
    display: flex;
    justify-content: space-between;
    width: 170px;
    color: #FDEF9E;
    font-size: 18px;
    font-weight: 600;
    margin-top: 40px;
  }

  .bgs {
    background-color: rgba(214, 206, 206, 0.7);
  }

  .lipinsu {
    position: absolute;
    font-size: 16px;
    color: #FDEF9E;
    margin-top: 40px;
    display: inline-block;
    /* margin-left: 20px; */
  }

  .liwu {
    width: 300px;
  }

  .wrapper {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    padding-bottom: 50px;
  }

  .springimg {
    width: 80%;
    display: block;
    border-radius: 15px;
    margin-top: 20px;
  }

  .codeimg {
    height: 25px;
  }

  .spring {
    width: 100vw;
    /* background: url('../../assets/spring/chunjie.jpg'); */
    position: relative;
    box-sizing: border-box;
    overflow: hidden;
  }

  .bg {
    width: 100%;
    display: block;
  }

  .rule {
    left: 0;
    top: 0;
    position: absolute;
    width: 100%;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 250px;
  }

  .ruleimg {
    background: url('../../../assets/spring/text_bg.png');
    background-size: 100% 100%;
    width: 200px;
    height: 32px;
    line-height: 32px;
    text-align: center;
    color: #FDEF9E;
    font-size: 24px;
  }

  .mt10 {
    margin-top: 20px;
  }

  .rulefont {
    background: #fff;
    border-radius: 10px;
    width: 90%;
    /* height: 330px; */
    font-size: 12px;
    padding: 10px;
    margin-top: 10px;
  }
</style>

import request from "@/utils/request";
/**
 * 课程列表
 * @param {*} parameter
 */
export function courseList(params) {
  return request.post("/oa/hrmLearningClient/queryCoursePageList", params, {
    "Content-Type": "application/json;charset=UTF-8",
  });
}
/**
 * 课程开始学习
 * @param {*} parameter
 */
export function courseStart(params) {
  return request.post("/oa/hrmLearningClient/courseStart", params);
}
/**
 * 课程完成学习
 * @param {*} parameter
 */
export function courseFinish(params) {
  return request.post("/oa/hrmLearningClient/courseFinish", params);
}
/**
 * 课程学习进度
 * @param {*} parameter
 */
export function submitProgress(params) {
  return request.post("/oa/hrmLearningClient/submitProgress", params, {
    "Content-Type": "application/json;charset=UTF-8",
  });
}

/**
 * 课程考试信息
 * @param {*} parameter
 */
export function examinationInfo(params) {
  return request.post("/oa/hrmLearningClient/examinationInfo", params);
}

/**
 * 课程考试题目
 * @param {*} parameter
 */
export function questionList(params) {
  return request.post("/oa/hrmLearningClient/questionList", params);
}
/**
 * 提交试卷答案
 * @param {*} parameter
 */
export function submitQuestionAnswer(params) {
  return request.post("/oa/hrmLearningClient/submitQuestionAnswer", params, {
    "Content-Type": "application/json;charset=UTF-8",
  });
}
/**
 * 查询当前试卷成绩
 * @param {*} parameter
 */
export function examinationScore(params) {
  return request.post("/oa/hrmLearningClient/examinationScore", params);
}
<template>
  <div style="height:100%; width: 100%">
    <baidu-map style="height:100%; width: 100%" class="trail" :center="center" :zoom="zoom" :scroll-wheel-zoom="true"
      @ready="initMap">
      <bm-navigation anchor="BMAP_ANCHOR_TOP_RIGHT"></bm-navigation>
      <bm-control :offset="{ width: 200, height: 30 }" anchor="BMAP_ANCHOR_TOP_RIGHT" v-if="trackPath && trackPath.length">
        <div class="row align-center justify-between">
          <div class="progrress row align-center">
            <el-progress :percentage="percent" style="width: 300px" />
            <!-- <el-progress :text-inside="true" :stroke-width="26" :percentage="70" style="width: 300px"></el-progress> -->
          </div>
          <div class="row align-center margin-left-20 btns">
            <!-- 暂停后再次播放 -->
            <el-button icon="el-icon-video-play" @click="play()" v-if="!isMove || isPause" size="small"
              type="success"></el-button>
            <!-- 暂停 -->
            <el-button icon="el-icon-video-pause" @click="pause()" v-if="isMove" size="small" type="primary"></el-button>
            <!-- 结束 -->
            <el-button icon="el-icon-switch-button" @click="stop()" v-if="isMove" size="small" type="danger"></el-button>
            <!-- <v-button icon="fast-backward" @click="back()"></v-button>
            <a-button icon="fast-forward" @click="forward()"></v-button> -->
          </div>
        </div>
      </bm-control>
    </baidu-map>
  </div>
</template>
<script>
import DistanceTool from 'bmaplib.distancetool'
import bus from '@/utils/bus.js'
import mapUtil from '@/utils/map'
// import { getTracks } from '@/api/device'
export default {
  name: 'ContrailMap',
  props: {
    speed: {
      type: Number,
      default: 400
    },
    deviceSn: {
      type: [String, Number],
      default: ''
    },
    condition: {
      type: Object,
      default: () => { }
    },
    visible: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      keyword: '关键字',
      center: { lng: 120.320032, lat: 31.589666 },
      zoom: 3,
      BMap: null,
      map: null,
      distanceTool: null,
      icon: {
        url: '@/assets/car/online.png',
        size: { width: 52, height: 26 },
        opts: { anchor: { width: 27, height: 13 } }
      },
      trackPath: [],
      polyline: [],
      times: '100',
      startFlag: 'Y', // Y启动有效N启动无效
      stopFlag: 'N', // 是否停止Y暂停N启动
      percent: 0, // 进度条进度
      stride: 0, // 快进/快退的步幅
      trackPointData: {},
      bfShow: false, // 快进/快退按钮是否显示
      totalDis: 0,
      mkrBus: null, // 地图上走动的car 对象
      current: 0, // 当前播放到那个点
      isMove: true, // 值为true 播放中， false 停止
      isPause: false, // 值为true 暂停， 暂停时 isMove 为true
      changeStatus: 0,
    }
  },
  destroyed() {
    this.distanceTool && this.distanceTool.close()
    this.timer && clearInterval(this.timer)
  },
  async created() { },
  computed: {
    mapNums() {
      return 100 * (this.stride === 0 ? 1 : this.stride)
    }
  },
  watch: {
    speed: {
      handler() {
        this.times = this.speed
      },
      immediate: true
    },
    percent: {
      handler() {
        this.$emit('changePercent', this.percent)
      }
    },
    deviceSn: {
      handler(nVal) {
        this.trackPath = []
        nVal && this.map && this.initTraks()
      },
      immediate: true
    },
    totalDis: {
      handler() {
        this.$emit('changeDis', this.totalDis)
      }
    },
    visible() {
      // 关闭窗口，清空定时器
      if (!this.visible) {
        clearInterval(this.timer)
        this.timer = null
        this.percent = 0
      }
    },
    zoom() {
    }
  },
  methods: {
    async getData() {
      bus.$emit('getloadding', true)
      if (!this.deviceSn) {
        this.$message({ message: '请选择设备', type: 'warning' })
        return
      }
      const parameter = {
        deviceSn: this.deviceSn,
        startTime: this.condition.startTime,
        endTime: this.condition.endTime,
        speed: (this.condition.checkedList && this.condition.checkedList.length > 0) || false,
        realData: false,
        gps: this.condition.gps === 1,
        correct: this.condition.correct === 1,
        address: this.condition.address
      }
      const res = await this.$store.dispatch('map/getTracks', parameter)
      console.log(res, 'res  =====')
      bus.$emit('getloadding', false)
      this.trackPath = res.success ? this.condition.correct === 1 ? res.content.listCheck : res.content.listCheck : []
      this.totalDis = res.success ? (res.content.mileage / 1000) : 0
      if (!this.trackPath || !this.trackPath.length) {
        this.$message({ message: '该设备在所选条件下，没有轨迹数据！', type: 'warning' })
      }
    },
    openDistanceTool() {
      const { distanceTool } = this
      distanceTool && distanceTool.open()
    },
    async initMap({ BMap, map }) {
      this.BMap = BMap
      this.map = map
      this.zoom = 19
      this.distanceTool = new DistanceTool(map, { lineStroke: 2 })
    },
    initTraks() {
      mapUtil.clearMoveCar(this.map)
      // 设置起始点及预计抵达时间
      const firstPoint = this.trackPath[0]
      if (firstPoint && firstPoint.lat) {
        this.center = mapUtil.setCenter(firstPoint)
        mapUtil.addMarker(this.map, Object.assign(firstPoint, { icon: 'start.png' }))
        mapUtil.addMarker(this.map, Object.assign(this.trackPath[this.trackPath.length - 1], { icon: 'end.png' }))
        // 创建轨迹
        mapUtil.addPolyline(this.map, this.trackPath)
      }
    },
    // 开始/ 重新开始 轨迹回放
    async start() {
      this.totalDis = 0
      await this.getData()
      if (this.trackPath == null || this.trackPath.length === 0) {
        return
      }
      this.initTraks()
      this.mkrBus = mapUtil.initMoveCar(this.map, this.trackPath[0])
      this.play()
    },
    // 复位
    reset() {
      this.timer && clearInterval(this.timer)
      this.timer = null
      this.percent = 0
      // 清空地图
      mapUtil.clearMoveCar(this.map)
    },
    play() {
      const _this = this
      // _this.zoom = 14
      this.isMove = true
      this.isPause = false
      const data = this.trackPath // 部分入参
      const count = this.trackPath.length
      let p = _this.current
      let _BASE = count > this.times ? Math.ceil(count / _this.mapNums) : 0
      let i = _BASE ? p / _BASE : p // 实际循环次数， 用来标记进度条， 每执行一次进度条增加1%
      this.timer = setInterval(() => {
        _BASE = count > this.times ? Math.ceil(count / _this.mapNums) : 0
        i++
        p = !_BASE ? i : (p + _BASE)
        const currentItem = data[p]
        _this.percent = !_BASE ? (Math.ceil(_this.mapNums / count) * i) : i
        // 播放结束
        if (p >= count) {
          clearInterval(_this.timer);
          this.current = 0
          this.percent = 100
          this.isMove = false
        }
        // 暂停
        if (_this.isPause) {
          this.current = p
          clearInterval(_this.timer)
        }
        // 停止
        if (!_this.isMove) {
          this.current = 0
          clearInterval(_this.timer)
        }
        if (currentItem && currentItem.lat) {
          mapUtil.moveCar(this.mkrBus, currentItem)
          // 当前点距离屏幕100px，则更新中心店位置
          const pixel = mapUtil.pointToPixel(this.map, currentItem)
          const scropW = document.body.clientWidth
          const scropH = document.body.clientHeight
          const x = pixel.x
          const y = pixel.y
          if (x < 100 || y < 100 || x > scropW - 100 || y > scropH - 100) {
            this.center = mapUtil.setCenter(currentItem)
          }
        }
      }, (1000 - _this.times))
    },
    // 轨迹回放停止
    stop() {
      clearInterval(this.timer)
      this.percent = 0
      mapUtil.moveCar(this.mkrBus, this.trackPath[0])
      this.isMove = false
      this.isPause = false
    },
    // 暂停
    pause() {
      this.isPause = true
    },
    // 快进
    forward() {
      this.stride++
      if (this.stride > 4) {
        this.stride = 4
        this.$message({ message: '播放速度最多放大4倍', type: 'warning' })
      }
    },
    // 快退
    back() {
      this.stride--
      if (this.stride < -4) {
        this.stride = -4
        this.$message({ message: '播放速度最多缩小4倍', type: 'warning' })
      }
    }
  }
}
</script>
<style lang="less" scoped>
.map {
  height: 100%;
}

.row {
  display: flex;
}

.col {
  display: flex;
  flex-direction: column;
}

.left {
  height: 100%;
  min-width: 100%;
  position: relative;
}

.progrress {
  width: 300px;
  height: 30px;
  background-color: #fff;
  padding: 0 10px;
  border-radius: 4px;
}

.btns {
  button {
    margin-left: 3px;
  }
}

.panorama_info {
  width: 50%;
  height: 100%;
  position: absolute;
  right: 0;
  top: 0;
}

.pan_close {
  position: relative;
  top: 20px;
  right: -120px;
  width: 35px;
  height: 35px;
  background: #f56c6c;
  z-index: 120100;
  border-radius: 4px;
  opacity: 0.6;

  .close_icon {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    color: #fff;
  }
}

/deep/ .anchorBL {
  display: none !important;
}
</style>
